import Layout from 'components/layout'
import Link from 'components/link'
import React from 'react'
import Text, { Heading } from 'components/text'
import Box, { Content } from 'components/box'

const Terms = () => (
  <Layout pageTitle="Terms of Service">
    <Content fontSize={[5, 4]} maxWidth="wide" my={[4, 4, 5]}>
      <Heading level={1} mb={-4} my={null}>
        Terms of Service
      </Heading>
      <Heading level={3} mb={3} mt={5} my={null}>
        1. Terms
      </Heading>
      <Text as="p" my={3}>
        By accessing the website at{' '}
        <Link color="blue" hoverColor="blue-600" to="/">
          https://addintent.com
        </Link>
        , you are agreeing to be bound by these terms of service, all applicable
        laws and regulations, and agree that you are responsible for compliance
        with any applicable local laws. If you do not agree with any of these
        terms, you are prohibited from using or accessing this site. The
        materials contained in this website are protected by applicable
        copyright and trademark law.
      </Text>
      <Heading level={3} mb={3} mt={5} my={null}>
        2. Use License
      </Heading>
      <Box as="ol" type="a" my={3} pl={[3, 0]}>
        <Box as="li" my={3}>
          Permission is granted to temporarily download one copy of the
          materials (information or software) on Intent's website for personal,
          non-commercial transitory viewing only. This is the grant of a
          license, not a transfer of title, and under this license you may not:
          <Box as="ol" type="i" my={4} pl={4}>
            <Box as="li" my={3}>
              modify or copy the materials;
            </Box>
            <Box as="li" my={3}>
              use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </Box>
            <Box as="li" my={3}>
              attempt to decompile or reverse engineer any software contained on
              Intent's website;
            </Box>
            <Box as="li" my={3}>
              remove any copyright or other proprietary notations from the
              materials; or
            </Box>
            <Box as="li" my={3}>
              transfer the materials to another person or "mirror" the materials
              on any other server.
            </Box>
          </Box>
        </Box>
        <Box as="li" my={3}>
          This license shall automatically terminate if you violate any of these
          restrictions and may be terminated by Intent at any time. Upon
          terminating your viewing of these materials or upon the termination of
          this license, you must destroy any downloaded materials in your
          possession whether in electronic or printed format.
        </Box>
      </Box>
      <Heading level={3} mb={3} mt={5} my={null}>
        3. Disclaimer
      </Heading>
      <Box as="ol" type="a" my={3} pl={[3, 0]}>
        <Box as="li" my={3}>
          The materials on Intent's website are provided on an 'as is' basis.
          Intent makes no warranties, expressed or implied, and hereby disclaims
          and negates all other warranties including, without limitation,
          implied warranties or conditions of merchantability, fitness for a
          particular purpose, or non-infringement of intellectual property or
          other violation of rights.
        </Box>
        <Box as="li" my={3}>
          Further, Intent does not warrant or make any representations
          concerning the accuracy, likely results, or reliability of the use of
          the materials on its website or otherwise relating to such materials
          or on any sites linked to this site.
        </Box>
      </Box>
      <Heading level={3} mb={3} mt={5} my={null}>
        4. Limitations
      </Heading>
      <Text as="p" my={3}>
        In no event shall Intent or its suppliers be liable for any damages
        (including, without limitation, damages for loss of data or profit, or
        due to business interruption) arising out of the use or inability to use
        the materials on Intent's website, even if Intent or a Intent authorized
        representative has been notified orally or in writing of the possibility
        of such damage. Because some jurisdictions do not allow limitations on
        implied warranties, or limitations of liability for consequential or
        incidental damages, these limitations may not apply to you.
      </Text>
      <Heading level={3} mb={3} mt={5} my={null}>
        5. Accuracy of materials
      </Heading>
      <Text as="p" my={3}>
        The materials appearing on Intent's website could include technical,
        typographical, or photographic errors. Intent does not warrant that any
        of the materials on its website are accurate, complete or current.
        Intent may make changes to the materials contained on its website at any
        time without notice. However Intent does not make any commitment to
        update the materials.
      </Text>
      <Heading level={3} mb={3} mt={5} my={null}>
        6. Links
      </Heading>
      <Text as="p" my={3}>
        Intent has not reviewed all of the sites linked to its website and is
        not responsible for the contents of any such linked site. The inclusion
        of any link does not imply endorsement by Intent of the site. Use of any
        such linked website is at the user's own risk.
      </Text>
      <Heading level={3} mb={3} mt={5} my={null}>
        7. Modifications
      </Heading>
      <Text as="p" my={3}>
        Intent may revise these terms of service for its website at any time
        without notice. By using this website you are agreeing to be bound by
        the then current version of these terms of service.
      </Text>
      <Heading level={3} mb={3} mt={5} my={null}>
        8. Governing Law
      </Heading>
      <Text as="p" my={3}>
        These terms and conditions are governed by and construed in accordance
        with the laws of Manila, Philippines and you irrevocably submit to the
        exclusive jurisdiction of the courts in that State or location.
      </Text>
    </Content>
  </Layout>
)

export default Terms
